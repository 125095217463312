import { GET_CATEGORIES_LIST_SUCCESS } from './categories.action';

const initialState = [];

export const categories = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORIES_LIST_SUCCESS:
            return [
                ...action.payload
            ];
        default:
            return state;
    }
};
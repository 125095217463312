import { getFilterDefaultValue } from '../../../shared/utils/product-filter.util';
import { DELETE_PRODUCTS_FILTER, UPDATE_PRODUCTS_FILTER } from './products-filters.action';

const initialState = {
    primary: null,
    secondary: null
};

export const productsFilters = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PRODUCTS_FILTER:
            return {
                ...state,
                [action.payload.flow]: action.payload.filters ? { ...action.payload.filters } : null
            };
        case DELETE_PRODUCTS_FILTER:
            return {
                ...state,
                [action.payload.flow]: {
                    ...state[action.payload.flow],
                    [action.payload.key]: getFilterDefaultValue(action.payload.key)
                }
            };
        default:
            return state;
    }
};
import { productsApi } from '../../../+api/products.api';

export const GET_PRODUCTS_LIST_SUCCESS = 'GET_PRODUCTS_LIST_SUCCESS';

export const getProductsListSuccess = (products) => {
    return {
        type: GET_PRODUCTS_LIST_SUCCESS,
        payload: products
    };
};

export const getProducts = () => {
    return (dispatch) => {
        return productsApi.getAll()
            .then(data => dispatch(getProductsListSuccess(data.products)))
            .catch(err => {
                throw err;
            });
    };
};

import { UPDATE_UI_CONFIG } from './ui-config.action';

const initialState = localStorage.uiConfig ? JSON.parse(localStorage.uiConfig) : {
    country: 'Bulgaria',
    flow: 'primary',
    isLightTheme: true,
    sideNavOpened: false,
};

export const uiConfig = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_UI_CONFIG:
            return {
                ...state,
                ...action.updatedProps
            };
        default:
            return state;
    }
};
import { productsApi } from '../../../../+api/products.api';

export const GET_PRODUCT_ADDITIONS_SUCCESS = 'GET_PRODUCT_ADDITIONS_SUCCESS';
export const GET_PRODUCT_ADDITIONS_FAIL = 'GET_PRODUCT_ADDITIONS_FAIL';

export const getProductAdditionsSuccess = (data) => {
    return {
        type: GET_PRODUCT_ADDITIONS_SUCCESS,
        payload: data
    };
};

export const getProductAdditionsFail = (error) => {
    return {
        type: GET_PRODUCT_ADDITIONS_FAIL,
        payload: error
    };
};

export const getProductAdditions = (props) => {
    return (dispatch) => {
        return productsApi.getProductAdditions(props)
            .then(data => dispatch(getProductAdditionsSuccess(data)))
            .catch(err => {
                dispatch(getProductAdditionsFail(err));
            });
    };
};




import {
    ADD_PRODUCT_TO_FAVORITES_SUCCESS,
    GET_FAVORITE_PRODUCTS_LIST_SUCCESS, REMOVE_PRODUCT_TO_FAVORITES_SUCCESS
} from './favorite-products.action';

const initialState = [];

export const favoriteProducts = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAVORITE_PRODUCTS_LIST_SUCCESS:
            return [...action.payload];
        case ADD_PRODUCT_TO_FAVORITES_SUCCESS:
            return [...state, action.payload];
        case REMOVE_PRODUCT_TO_FAVORITES_SUCCESS:
            return state.filter(({ id }) => id !== action.payload);
        default:
            return state;
    }
};
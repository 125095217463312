export const defaultValues = {
    sortBy: 'popularity',
    where: '',
    priceRange: {
        min: 0,
        max: 999
    },
    participants: {
        group: '',
        count: 0
    },
    rating: 0,
    regions: [],
    bookingFlags: {
        onlineBooking: false,
        freeCancellation: false,
        bookingInquery: false,
        noCancellation: false,
        bookByPhone: false,
    },
};

export const getFilterDefaultValue = (key) => {
    if (typeof defaultValues[key] === 'object') {
        return JSON.parse(JSON.stringify(defaultValues[key]));
    }

    return defaultValues[key];
};

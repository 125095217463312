import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reduxCatch from 'redux-catch';

function errorHandler(error, getState, lastAction, dispatch) {
    //todo add error handling/logger here
    // console.error(error);
    // console.debug('current state', getState());
    // console.debug('last action was', lastAction);
}

export const configureStore = (rootReducer) => {
    const thunkMiddleware = applyMiddleware(thunk);

    return createStore(
        rootReducer,
        process.env.NODE_ENV === 'development'
            ? window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__(thunkMiddleware) : thunkMiddleware
            : applyMiddleware(reduxCatch(errorHandler), thunk)
    );
};

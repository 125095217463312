import { authApi } from '../../../+api/auth.api';
import { authUtils } from '../../../shared/utils/auth.utils';

export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_PHONE_FIRST_STEP_SUCCESS = 'AUTH_LOGIN_PHONE_FIRST_STEP_SUCCESS';
export const AUTH_LOGIN_PHONE_SECOND_STEP_SUCCESS = 'AUTH_LOGIN_PHONE_SECOND_STEP_SUCCESS';
export const AUTH_FORGOT_PASS_SUCCESS = 'AUTH_FORGOT_PASS_SUCCESS';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const registerSuccess = (data) => {
    authUtils.setAuthDataToLocalStorage(data);

    return {
        type: AUTH_REGISTER_SUCCESS,
        payload: data
    };
};

export const loginSuccess = (data) => {
    authUtils.setAuthDataToLocalStorage(data);

    return {
        type: AUTH_LOGIN_SUCCESS,
        payload: data
    };
};

export const loginViaPhoneFirstStepSuccess = () => {
    return {
        type: AUTH_LOGIN_PHONE_FIRST_STEP_SUCCESS,
    };
};

export const loginViaPhoneSecondStepSuccess = (data) => {
    authUtils.setAuthDataToLocalStorage(data);

    return {
        type: AUTH_LOGIN_PHONE_SECOND_STEP_SUCCESS,
        payload: data
    };
};

export const forgotPassSuccess = (data) => {
    return {
        type: AUTH_FORGOT_PASS_SUCCESS,
        payload: data
    };
};

export const logoutSuccess = () => {
    authUtils.clearAuthDataFromLocalStorage();

    return {
        type: AUTH_LOGOUT_SUCCESS,
    };
};

export const register = (postData) => {
    return (dispatch) => {
        return authApi.register(postData)
            .then(data => dispatch(registerSuccess(data)))
            .catch(err => {
                throw err;
            });
    };
};

export const login = (postData) => {
    return (dispatch) => {
        return authApi.login(postData)
            .then(data => dispatch(registerSuccess(data)))
            .catch(err => {
                throw err;
            });
    };
};

export const loginViaPhone = ({ country, phoneNumber }) => {
    return (dispatch) => {
        return authApi.loginViaPhoneNumber({ country, phoneNumber })
            .then(data => dispatch(loginViaPhoneFirstStepSuccess()))
            .catch(err => {
                throw err;
            });
    };
};

export const loginViaPhoneCodeConfirm = ({ country, phoneNumber, code }) => {
    return (dispatch) => {
        return authApi.loginViaPhoneNumberCodeConfirm({ country, phoneNumber, code })
            .then(data => dispatch(loginViaPhoneSecondStepSuccess(data)))
            .catch(err => {
                throw err;
            });
    };
};

export const forgotPassword = (email) => {
    return (dispatch) => {
        return authApi.forgotPass(email)
            .then(data => dispatch(forgotPassSuccess()))
            .catch(err => {
                throw err;
            });
    };
};

export const logout = () => {
    return (dispatch) => {
        return authApi.logout()
            .then(data => dispatch(logoutSuccess()))
            .catch(err => {
                throw err;
            });
    };
};


import { categoriesApi } from '../../../+api/categories.api';

export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';

export const getCategoriesListSuccess = (categories) => {
    return {
        type: GET_CATEGORIES_LIST_SUCCESS,
        payload: categories
    };
};

export const getCategories = () => {
    return (dispatch) => {
        return categoriesApi.getAll()
            .then(data => dispatch(getCategoriesListSuccess(data.categories)))
            .catch(err => {
                throw err;
            });
    };
};


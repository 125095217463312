import { ajax } from '../shared/utils/ajax-requests.util';

export const authApi = {
    register: (postData) => {
        return ajax.post({ url: 'register', postData });
    },
    login: ({ email, password, ...rest }) => {
        return ajax.post({
            url: 'token', postData: {
                email,
                password,
                ...rest
            }
        });
    },
    loginViaPhoneNumber: ({ country, phoneNumber }) => {
        return ajax.post({
            url: 'login-phone', postData: { country, phoneNumber }
        });
    },
    loginViaPhoneNumberCodeConfirm: ({ country, phoneNumber, code }) => {
        return ajax.post({
            url: 'login-phone-confirm', postData: { country, phoneNumber, code }
        });
    },
    logout: () => {
        return ajax.post({
            url: 'logout ', postData: {}
        });
    },
    forgotPass: (email) => {
        return ajax.post({
            url: 'forgot-pass ', postData: { email }
        });
    }
};
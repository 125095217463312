import {
    AUTH_FORGOT_PASS_SUCCESS, AUTH_LOGIN_PHONE_FIRST_STEP_SUCCESS,
    AUTH_LOGIN_PHONE_SECOND_STEP_SUCCESS,
    AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS,
    AUTH_REGISTER_SUCCESS
} from './auth.action';

const initialState = {
    accessToken: null,
    userData: null,
    forgotPassEmailSent: false,
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_REGISTER_SUCCESS:
        case AUTH_LOGIN_SUCCESS:
        case AUTH_LOGIN_PHONE_SECOND_STEP_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.access_token
            };
        case AUTH_FORGOT_PASS_SUCCESS:
            return {
                ...state,
                forgotPassEmailSent: true
            };
        case AUTH_LOGOUT_SUCCESS:
            return {
                ...state,
                accessToken: null
            };
        case AUTH_LOGIN_PHONE_FIRST_STEP_SUCCESS:
        default:
            return state;
    }
};
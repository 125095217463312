import { combineReducers } from 'redux';
import { productsFilters } from './slices/products-filter/products-filters.reducer';
import { uiConfig } from './slices/ui-config/ui-config.reducer';
import { auth } from './slices/auth/auth.reducer';
import { products } from './slices/products/products.reducer';
import { categories } from './slices/categories/categories.reducer';
import { favoriteProducts } from './slices/favorite-products/favorite-products.reducer';
import { productDetails } from './slices/product-details/product-details.reducer';
import { productAvailability } from './slices/product-booking-configuration/product-availability/product-availability.reducer';
import { productAdditions } from './slices/product-booking-configuration/product-additions/product-additions.reducer';
import { productAdditionTimeSlots } from './slices/product-booking-configuration/product-addition-time-slots/product-addition-time-slots.reducer';

export const rootReducer = combineReducers({
    productsFilters,
    uiConfig,
    auth,
    products,
    productDetails,
    productAvailability,
    productAdditions,
    productAdditionTimeSlots,
    categories,
    favoriteProducts,
});

import { GET_PRODUCT_DETAILS_FAIL, GET_PRODUCT_DETAILS_SUCCESS } from './product-details.action';

const initialState = {
    product: null,
    error: null
};

export const productDetails = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                error: null,
                product: action.payload
            };
        case GET_PRODUCT_DETAILS_FAIL:
            return {
                product: null,
                error: action.payload
            };
        default:
            return state;
    }
};
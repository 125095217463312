import React from 'react';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../+store/slices/auth/auth.action';


export function AppMainBarAccountButton() {
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector(state => state.auth.accessToken);

    return <AccountCircleOutlinedIcon onClick={() => {
        if (token) {
            dispatch(logout());
        } else {
            history.push(`/auth`);
        }
    }}/>;
}


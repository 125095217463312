import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateUIConfig } from '../../+store/slices/ui-config/ui-config.action';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
});

export function MainMenuSidenav() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t, i18n } = useTranslation(['main', 'common']);
    const sideNavOpened = useSelector(state => state.uiConfig.sideNavOpened);
    const isLightTheme = useSelector(state => state.uiConfig.isLightTheme);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        dispatch(updateUIConfig({ sideNavOpened: open }));
    };

    return (
        <SwipeableDrawer
            anchor={'left'}
            open={sideNavOpened}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <div
                className={clsx(classes.list)}
                role='presentation'
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <List>
                    <ListItem button key={'home'} component={Link} to={'/'}>
                        <ListItemIcon><InboxIcon/></ListItemIcon>
                        <ListItemText primary={t('sidenav.home')}/>
                    </ListItem>
                </List>
                <List>
                    <ListItem button key={'products'} component={Link} to={'/products'}>
                        <ListItemIcon><MailIcon/></ListItemIcon>
                        <ListItemText primary={'Products'}/>
                    </ListItem>
                </List>
                <Divider/>
            </div>

            <ListItem key={'theme'}>
                <ListItemText primary={'Theme'}/>
                <ListItemIcon><Switch
                    color={'primary'}
                    checked={isLightTheme}
                    onChange={(event) => {
                        dispatch(updateUIConfig({ isLightTheme: event.target.checked }));
                    }}
                    name='Theme'/></ListItemIcon>
            </ListItem>

            <ListItem key={'language'}>
                <ListItemText primary={'Language'}/>

                <ListItemIcon>
                    <ButtonGroup>
                        <Button variant={i18n.language === 'de' ? 'contained' : ''}
                                color={'primary'}
                                onClick={() => i18n.changeLanguage('de')}
                                disableElevation>de</Button>
                        <Button variant={i18n.language === 'en' ? 'contained' : ''}
                                color={'primary'}
                                onClick={() => i18n.changeLanguage('en')}
                                disableElevation>en</Button>
                    </ButtonGroup>
                </ListItemIcon>
            </ListItem>
        </SwipeableDrawer>
    );
}

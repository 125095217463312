import {
    GET_PRODUCT_DAYS_AVAILABILITY_SUCCESS,
    GET_PRODUCT_DAYS_AVAILABILITY_FAIL, GET_PRODUCT_TIME_AVAILABILITY_FAIL, GET_PRODUCT_TIME_AVAILABILITY_SUCCESS
} from './product-availability.action';

const initialState = {
    days: {},
    timeSlots: []
};

export const productAvailability = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_DAYS_AVAILABILITY_SUCCESS:
            return {
                days: action.payload,
                timeSlots: []
            };
        case GET_PRODUCT_DAYS_AVAILABILITY_FAIL:
            return {
                days: {},
                timeSlots: []
            };
        case GET_PRODUCT_TIME_AVAILABILITY_SUCCESS:
            return {
                ...state,
                timeSlots: action.payload
            };
        case GET_PRODUCT_TIME_AVAILABILITY_FAIL:
            return {
                ...state,
                timeSlots: []
            };
        default:
            return state;
    }
};
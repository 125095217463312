import { ajax } from '../shared/utils/ajax-requests.util';

export const favoriteProductsApi = {
    getAll: () => {
        return ajax.get({ url: `${window.location.protocol}//${window.location.host}/mock/favorite-products.json` }).then(x => {
            return { favoriteProducts: [] };
        });
    },
    addOne: (product) => {
        return ajax.post({
            url: `${window.location.protocol}//${window.location.host}/mock/favorite-products`,
            postData: { id: product.id }
        });
    },
    removeOne: (productId) => {
        return ajax.delete({ url: `${window.location.protocol}//${window.location.host}/mock/favorite-products/${productId}` });
    }
};
import { favoriteProductsApi } from '../../../+api/favorite-products.api';

export const GET_FAVORITE_PRODUCTS_LIST_SUCCESS = 'GET_FAVORITE_PRODUCTS_LIST_SUCCESS';
export const ADD_PRODUCT_TO_FAVORITES_SUCCESS = 'ADD_PRODUCT_TO_FAVORITES_SUCCESS';
export const REMOVE_PRODUCT_TO_FAVORITES_SUCCESS = 'REMOVE_PRODUCT_TO_FAVORITES_SUCCESS';

export const getFavoriteProductsListSuccess = (favoriteProducts) => {
    return {
        type: GET_FAVORITE_PRODUCTS_LIST_SUCCESS,
        payload: favoriteProducts
    };
};

export const addFavoriteProductSuccess = (favoriteProduct) => {
    return {
        type: ADD_PRODUCT_TO_FAVORITES_SUCCESS,
        payload: favoriteProduct
    };
};

export const removeFavoriteProductSuccess = (favoriteProductId) => {
    return {
        type: REMOVE_PRODUCT_TO_FAVORITES_SUCCESS,
        payload: favoriteProductId
    };
};


export const getFavoriteProducts = () => {
    return (dispatch) => {
        return favoriteProductsApi.getAll()
            .then(data => dispatch(getFavoriteProductsListSuccess(data.favoriteProducts)))
            .catch(err => {
                throw err;
            });
    };
};

export const addFavoriteProduct = (product) => {
    return (dispatch) => {
        return favoriteProductsApi.addOne(product)
            .then(data => dispatch(addFavoriteProductSuccess(product)))
            .catch(err => {
                throw err;
            });
    };
};


export const removeFavoriteProduct = (productId) => {
    return (dispatch) => {
        return favoriteProductsApi.removeOne(productId)
            .then(data => dispatch(removeFavoriteProductSuccess(productId)))
            .catch(err => {
                throw err;
            });
    };
};


import React from 'react';
import './ContentLoaderAnimation.scss';

export const ContentLoaderAnimation = ({ height }) => {
    const rowsCount = height / 66;

    const rows = [];
    for (let i = 0; i <= rowsCount; i++) {
        rows.push(<tr key={i}>
            <td className='td-3'><span/></td>
        </tr>);
    }

    return <div style={{ height, overflow: 'hidden' }}>
        <table className={'content-loader-animation'}>
            <tbody>
            {rows}
            </tbody>
        </table>
    </div>;
};


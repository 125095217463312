import { productsApi } from '../../../+api/products.api';

export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';
export const GET_PRODUCT_DETAILS_FAIL = 'GET_PRODUCT_DETAILS_FAIL';

export const getProductDetailsSuccess = (product) => {
    return {
        type: GET_PRODUCT_DETAILS_SUCCESS,
        payload: product
    };
};

export const getProductDetailsFail = (error) => {
    return {
        type: GET_PRODUCT_DETAILS_FAIL,
        payload: error
    };
};


export const getProductDetails = (productId) => {
    return (dispatch) => {
        return productsApi.getOne(productId)
            .then(data => dispatch(getProductDetailsSuccess(data)))
            .catch(err => {
                dispatch(getProductDetailsFail(err));
            });
    };
};


import { ajax } from '../shared/utils/ajax-requests.util';

export const productsApi = {
    getAll: () => {
        return ajax.get({ url: `${window.location.protocol}//${window.location.host}/mock/products.json` });
    },
    getOne: (productId) => {
        return ajax.get({ url: `${window.location.protocol}//${window.location.host}/mock/products/${productId}.json` });
    },
    getProductAvailability: ({ productId, locationId, ...rest }) => {
        return ajax.get({
            url: `${window.location.protocol}//${window.location.host}/mock/products/availability/dates/${productId}-${locationId}.json`,
            getData: rest
        });
    },
    getProductTimeAvailability: ({ productId, locationId, ...rest }) => {
        return ajax.get({
            url: `${window.location.protocol}//${window.location.host}/mock/products/availability/${productId}-${locationId}.json`,
            getData: rest
        });
    },
    getProductAdditions: ({ productId, ...rest }) => {
        return ajax.get({
            url: `${window.location.protocol}//${window.location.host}/mock/products/additions/${productId}.json`,
            getData: rest
        });
    },
    getProductAdditionTimeSlots: ({ productId, resourceId, days, additionId }) => {
        return ajax.get({
            url: `${window.location.protocol}//${window.location.host}/mock/products/additions/time-slots/test.json`,
            getData: { productId, resourceId, days, additionId }
        });
    }
};
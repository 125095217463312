import {
    GET_PRODUCT_ADDITION_TIME_SLOTS_SUCCESS,
    GET_PRODUCT_ADDITION_TIME_SLOTS_FAIL
} from './product-addition-time-slots.action';

const initialState = {};

export const productAdditionTimeSlots = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_ADDITION_TIME_SLOTS_SUCCESS:
            return action.payload;
        case GET_PRODUCT_ADDITION_TIME_SLOTS_FAIL:
            return {};
        default:
            return state;
    }
};
import { authUtils } from './auth.utils';

window.API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

let userIp;
findLocalIp().then(ip => userIp = ip);

function getHeaders() {
    return {
        'content-type': 'application/json',
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`,
        'IP': userIp,
        'Accept': 'application/json'
    };
}

function getFullUrl(url) {
    return url.indexOf('http') === 0 ? url : `${window.API_BASE_URL}${url}`;
}

export const ajax = {
    get: ({ url, getData, signal }) => {
        return fetch(`${getFullUrl(url)}${getData ? '?data=' + encodeURIComponent(JSON.stringify(getData)) : ''}`, {
            cache: 'no-cache',
            headers: getHeaders(),
            method: 'GET',
            mode: 'cors',
            signal,
        })
            .then(checkIfSuccess).then(handleSuccess).catch(handleFailure);
    },
    post: ({ url, postData }) => {
        return fetch(getFullUrl(url), {
            body: JSON.stringify(postData), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *omit
            headers: getHeaders(),
            method: 'POST', // *GET, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *same-origin
            redirect: 'follow', // *manual, error
            referrer: 'no-referrer', // *client
        })
            .then(checkIfSuccess).then(handleSuccess).catch(handleFailure);
    },
    put: ({ url, postData, signal }) => {
        return fetch(getFullUrl(url), {
            body: JSON.stringify(postData),
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: getHeaders(),
            method: 'PUT',
            mode: 'cors',
            signal,
        })
            .then(checkIfSuccess).then(handleSuccess).catch(handleFailure);
    },
    delete: ({ url }) => {
        return fetch(getFullUrl(url), {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: getHeaders(),
            method: 'DELETE',
            mode: 'cors',
        })
            .then(checkIfSuccess).then(handleSuccess).catch(handleFailure);
    },
};


async function checkIfSuccess(response) {
    if (!response.ok) {
        if (+response.headers.get('content-length') === 0) {
            //    Handle the case where the server crashes/does not respond
        }

        return Promise.reject({
            statusText: response.statusText,
            errorResponse: await response.json()
        });
    }

    return await response.json();
}

function handleSuccess(responseJson) {
    refreshToken();
    return responseJson;
}

let refreshTokenTrigger;

function refreshToken() {
    if (refreshTokenTrigger) {
        return;
    }

    const fiveMinutes = 300000;
    refreshTokenTrigger = setTimeout(() => {
        refreshTokenTrigger = null;
        fetch(`${getFullUrl('refreshtoken')}`, {
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'Refreshtoken': localStorage.refresh_token,
                'IP': userIp,
                'Accept': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
        }).then(response => response.json()).then(data => {
            authUtils.setAuthDataToLocalStorage(data);
            window.queryClient.setQueryData('access_token', data.access_token);
        }).catch(e => {

        });
    }, fiveMinutes);
}

function handleFailure(err) {
    if (err.statusText === 'Unauthorized') {
        authUtils.clearAuthDataFromLocalStorage();
        window.queryClient.invalidateQueries('access_token');
    }

    if (err.errorResponse) {
        return Promise.reject({
            errorResponse: err.errorResponse
        });
    }

    if (err.message === 'Failed to fetch') {
        return Promise.reject({
            errorResponse: err.message
        });
    }

    //Should not reach here. If there are others ways/reasons for requests to fail they should be handled
    console.log(err);
    return Promise.reject({
        errorResponse: err
    });
}

function findLocalIp() {
    return new Promise((resolve, reject) => {
        window.RTCPeerConnection = window.RTCPeerConnection
            || window.mozRTCPeerConnection
            || window.webkitRTCPeerConnection;

        if (typeof window.RTCPeerConnection === 'undefined') {
            return reject('WebRTC not supported by browser');
        }

        let pc = new RTCPeerConnection();
        let ips = [];

        pc.createDataChannel('');

        pc.createOffer()
            .then(offer => pc.setLocalDescription(offer))
            .catch(err => reject(err));

        pc.onicecandidate = event => {
            if (!event || !event.candidate) {
                if (ips.length === 0) {
                    return reject('WebRTC disabled or restricted by browser');
                }

                return resolve(ips);
            }

            let parts = event.candidate.candidate.split(' ');
            let ip = parts[4];

            if (!ips.some(e => e === ip)) {
                ips.push(ip);
            }

            return resolve(ip);
        };
    });
}

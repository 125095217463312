export const UPDATE_PRODUCTS_FILTER = 'UPDATE_PRODUCTS_FILTER';
export const DELETE_PRODUCTS_FILTER = 'DELETE_PRODUCTS_FILTER';

export const updateProductsFilter = (flow, filters) => {
    return {
        type: UPDATE_PRODUCTS_FILTER,
        payload: {
            filters,
            flow
        }
    };
};

export const deleteProductsFilter = (flow, key) => {
    return {
        type: DELETE_PRODUCTS_FILTER,
        payload: {
            flow,
            key
        }
    };
};
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { updateUIConfig } from '../../+store/slices/ui-config/ui-config.action';
import { AppMainBarAccountButton } from './AppMainBarAccountButton';

const useStyles = makeStyles((theme) => ({
    spacer: {
        width: '100%',
        height: 57
    },
    appBar: {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontSize: 18,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));


export function AppMainBar({ pageTitle }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    return <React.Fragment>
        <div className={classes.spacer}/>
        <AppBar position='fixed' className={classes.appBar}>
            <Toolbar>
                <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu'
                            onClick={() => {
                                dispatch(updateUIConfig({ sideNavOpened: true }));
                            }}>
                    <MenuIcon/>
                </IconButton>
                <Typography className={classes.title}>{pageTitle}</Typography>

                <div style={{ minWidth: 72 }}>
                    <Link to={'/'}><HomeOutlinedIcon/></Link>
                    <NotificationsOutlinedIcon/>
                    <AppMainBarAccountButton/>
                </div>
            </Toolbar>
        </AppBar>
    </React.Fragment>;
}


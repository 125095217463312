import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';
import './App.scss';
import React from 'react';
import { AppMainBar } from './shared/components/AppMainBar';
import { MainMenuSidenav } from './shared/components/MainMenuSidenav';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@emotion/react';
import { themeDark } from './theme-dark';
import { themeLight } from './theme-light';
import CssBaseline from '@material-ui/core/CssBaseline';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import common_de from './translations/de/common.json';
import common_en from './translations/en/common.json';
import main_de from './translations/de/main.json';
import main_en from './translations/en/main.json';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './+store';
import { getFavoriteProducts } from './+store/slices/favorite-products/favorite-products.action';
import { ContentLoaderAnimation } from './shared/components/ContentLoaderAnimation';
import { AppMainBarLogo } from './shared/components/AppMainBarLogo';

const ProductsRoute = React.lazy(() => import('./routes/products/ProductsRoute'));
const ProductDetailsRoute = React.lazy(() => import('./routes/products/product-details/ProductDetailsRoute'));
const ProductsMapViewRoute = React.lazy(() => import('./routes/products/products-map-view-route/ProductsMapViewRoute'));
const ProductsMapViewFilterTestRoute = React.lazy(() => import('./routes/products/ProductsMapViewFilterTestRoute'));
const ProductsSearchRoute = React.lazy(() => import('./routes/products/ProductsSearchRoute'));
const FavoriteProductsRoute = React.lazy(() => import('./routes/products/FavoriteProductsRoute'));
const ProductCategoriesRoute = React.lazy(() => import('./routes/products/product-categories/ProductCategoriesRoute'));
const ProductsFilterRoute = React.lazy(() => import('./routes/products/products-filter/ProductsFilterRoute'));
const AuthRoute = React.lazy(() => import('./routes/auth/Auth'));
const PhoneLogin = React.lazy(() => import( './routes/auth/PhoneLogin'));
const ForgotPass = React.lazy(() => import( './routes/auth/ForgotPass'));
const VoucherForm = React.lazy(() => import( './routes/voucher/VoucherForm'));
const VoucherDetails = React.lazy(() => import( './routes/voucher/VoucherDetails'));
const About = React.lazy(() => import( './routes/about/About'));
const Home = React.lazy(() => import( './routes/home/Home'));

function clearAppStoragesBasedOnVersion(v) {
    if (localStorage.appVersion !== v) {
        localStorage.removeItem('uiConfig');
    }
    localStorage.appVersion = v;
}

clearAppStoragesBasedOnVersion('1');

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    fallbackLng: 'en',
    nsMode: 'fallback',
    resources: {
        en: {
            common: common_en,
            main: main_en,
        },
        de: {
            common: common_de,
            main: main_de,
        },
    },
    react: {
        nsMode: 'fallback'
    }
});

function App() {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <ThemedApp/>
            </I18nextProvider>
        </Provider>
    );
}


function ThemedApp() {
    const dispatch = useDispatch();
    const isLightTheme = useSelector(state => state.uiConfig.isLightTheme);

    const appliedTheme = createMuiTheme(isLightTheme ? themeLight : themeDark);

    React.useEffect(() => {
        dispatch(getFavoriteProducts());
    }, [dispatch]);

    return <MuiThemeProvider theme={appliedTheme}>
        <ThemeProvider theme={appliedTheme}>
            <CssBaseline/>
            <TransitionGroup>
                <React.Suspense fallback={<CSSTransition
                    in={true}
                    timeout={300}
                    classNames={'page'}
                    unmountOnExit>
                    <ContentLoaderAnimation height={window.innerHeight}/>
                </CSSTransition>}>
                    <Router>
                        <MainMenuSidenav/>

                        <div>
                            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                            <Ruter/>
                        </div>
                    </Router>
                </React.Suspense>
            </TransitionGroup>
        </ThemeProvider>
    </MuiThemeProvider>;
}

const routes = [
    {
        path: '/auth',
        name: <AppMainBarLogo/>,
        Component: AuthRoute
    },
    {
        path: '/auth/phone',
        name: <AppMainBarLogo/>,
        Component: PhoneLogin
    },
    {
        path: '/auth/forgot-pass',
        name: <AppMainBarLogo/>,
        Component: ForgotPass
    },
    { path: '/about', name: 'About', Component: About },
    { path: '/products/:productId/:locationId?', name: 'Experience', Component: ProductDetailsRoute },
    { path: '/products-map/:productId/:locationId?', name: 'Experience', Component: ProductDetailsRoute },
    { path: '/products', name: 'Experiences', Component: ProductsRoute },
    { path: '/products-map', name: 'Near by me / Destination', Component: ProductsMapViewRoute },
    { path: '/products-search', name: 'Experiences Search', Component: ProductsSearchRoute },
    { path: '/products-favorites', name: 'Favorites', Component: FavoriteProductsRoute },
    { path: '/product-categories', name: 'Categories', Component: ProductCategoriesRoute },
    { path: '/products-filter/:hideRegionPicker?', name: 'Filter', Component: ProductsFilterRoute },
    {
        path: '/products-map-filter-test',
        name: 'Test Near by me / Destination',
        Component: ProductsMapViewFilterTestRoute
    },
    { path: '/voucher', name: <AppMainBarLogo/>, Component: VoucherForm },
    { path: '/voucher/:id', name: <AppMainBarLogo/>, Component: VoucherDetails },
    { path: '/', name: <AppMainBarLogo/>, Component: Home },
];

function Ruter() {
    const history = useHistory();
    React.useEffect(() => {
        return history.listen(() => window.scrollTo(0, 0));
    }, [history]);

    return <div className='container'>
        {routes.map(({ path, name, Component }) => (
            <Route key={path} exact path={path}>
                {({ match }) => {
                    return <CSSTransition
                        in={match != null}
                        timeout={300}
                        classNames={'page'}
                        unmountOnExit>
                        <div className={'page'}>
                            <AppMainBar pageTitle={name}/>
                            <Component/>
                        </div>
                    </CSSTransition>;
                }}
            </Route>
        ))}
    </div>;
}

if (process.env.NODE_ENV === 'development') {
    window.store = store;
    window.moment = moment;
}

export default App;

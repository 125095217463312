import {
    GET_PRODUCT_ADDITIONS_SUCCESS,
    GET_PRODUCT_ADDITIONS_FAIL,
} from './product-additions.action';

const initialState = [];

export const productAdditions = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_ADDITIONS_SUCCESS:
            return action.payload;
        case GET_PRODUCT_ADDITIONS_FAIL:
            return [];
        default:
            return state;
    }
};